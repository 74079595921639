<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}调价单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="调价仓库" prop="warehouse.id">
        <select-maintain
          v-model="form.data.warehouse"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, value) => (params.payload = { name: value }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['StockCostAdjustmentDto']>,
    code: String as PropType<'MRQ' | 'MRQR'>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.data?.id) {
            this.form.data.id = this.data?.id
            this.form.get()
          }
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'warehouse.id': [{ required: true, message: '请选择调价仓库' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/stock/cost/adjustment/:stockCostAdjustmentId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/stock/cost/adjustment',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/stock/cost/adjustment',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
